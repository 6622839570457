/*
 * workaround for materialize-css:
 * we want to use the validate class for CSS,
 * but we don't want the automatic validation behavior,
 * so we copy here all rules that involve the "validate" class, renamed to "my-validate"
 */
input:not([type]).my-validate + label,
input[type='text'].my-validate + label,
input[type='password'].my-validate + label,
input[type='email'].my-validate + label,
input[type='url'].my-validate + label,
input[type='time'].my-validate + label,
input[type='date'].my-validate + label,
input[type='datetime'].my-validate + label,
input[type='datetime-local'].my-validate + label,
input[type='tel'].my-validate + label,
input[type='number'].my-validate + label,
input[type='search'].my-validate + label,
input[type='checkbox'].my-validate + label,
textarea.materialize-textarea.my-validate + label,
.datepicker.my-validate + .picker + label {
  width: 100%;
  pointer-events: none;
}
.input-field.col .prefix ~ .my-validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}
.input-field .prefix ~ .my-validate ~ label {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}
.input-field label {
  left: 0;
}
.input-field label:after {
  transform: translateY(-120%);
}
.input-field label.my-active {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
  transform: translateY(-140%);
}
.input-field label.active:after,
.input-field label.my-active:after {
  transform: translateY(0);
}
.select-wrapper + label {
  width: 100%;
}
.select-wrapper + label:after {
  content: attr(data-error);
  color: #f44336;
  display: block;
  position: absolute;
  top: 88px;
  font-size: 14px;
}
input.datepicker + .picker + label:after {
  display: block;
  content: '';
  position: absolute;
  top: 60px;
  opacity: 0;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
input.datepicker.invalid + .picker + label:after {
  content: attr(data-error);
  color: #f44336;
  opacity: 1;
  width: 500px;
}
.invalid + label:after,
.invalid + .picker + label:after {
  font-size: 1rem;
}
.collapsible > li .collapsible-body {
  display: block;
}
.form-autocomplete-select .autocomplete-content {
  position: absolute;
}
body input[type='text']:focus:not([readonly]).invalid {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}
.picker {
  outline: none;
}
.main-title-color {
  color: #7D943C;
}
.spinner-color {
  border-color: #7D943C;
}
.main-background-color {
  background-color: #7D943C;
}
.main-text-color {
  color: #ffffff !important;
}
.light-background-color {
  background-color: #eee;
}
.danger-text-color {
  color: #b71c1c !important;
}
.light-danger-background-color {
  background-color: #ffebee !important;
}
.dark-button-text {
  color: #7D943C;
}
.light-text {
  color: #9e9e9e;
}
.validation-error-text-color {
  color: #f44336 !important;
}
.preloader-wrapper-center.preloader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.preloader-wrapper-center.preloader-wrapper .spinner-layer {
  border-color: #7D943C;
}
h1 {
  color: #7D943C;
}
body h1 {
  font-size: 2.2rem;
}
strong {
  font-weight: bold;
}
nav.navigation {
  background-color: #7D943C;
}
button.btn:hover.primary,
button:focus.primary {
  background-color: #7D943C;
}
button.btn:hover.secondary,
button:focus.secondary {
  background-color: #eee;
}
button.primary {
  background-color: #7D943C;
}
button.secondary {
  background-color: #eee;
  color: #9e9e9e;
}
button.btn-flat {
  background-color: #eee;
}
.remove > button.btn-flat,
.remove > button.primary {
  background-color: #ffebee !important;
  color: #343434;
}
input[type='checkbox']:checked + label:before {
  border-color: #502709;
}
input[type='checkbox'].filled-in:checked + label:after,
input[type='checkbox'].filled-in.tabbed:checked:focus + label:after {
  border-color: #502709;
  background-color: #502709;
}
.add-entity {
  position: fixed;
  right: 4em;
  bottom: 3em;
  z-index: 2;
}
@media (max-width: 600px) {
  body .add-entity {
    right: 1em;
    bottom: 1em;
  }
}
@media (min-width: 801px) {
  .small-screen-caption {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 800px) {
  .small-screen-caption {
    display: inline-block;
    font-weight: bold;
    width: 8em;
  }
  list-loader table:not(.picker__table) th {
    display: none;
  }
  body .container > list-loader.uncontainer > .list-loader-content > table td,
  body .container > list-loader.uncontainer > .list-loader-content > table td:first-child,
  body .container > list-loader.uncontainer > .list-loader-content > table td:last-child {
    padding: 10px 1rem;
  }
  body .container > .uncontainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer {
    padding-left: 0;
    padding-right: 0;
  }
  body .container > list-loader.uncontainer > *,
  body .container > list-loader.uncontainer td:first-child,
  body .container > list-loader.uncontainer td:last-child,
  body .container > list-loader.uncontainer th:first-child,
  body .container > list-loader.uncontainer th:last-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer > .list-loader-content {
    padding-left: 0;
    padding-right: 0;
  }
  .list-loader-content > table td.actions-cell {
    margin-left: -3px;
  }
  list-loader table:not(.picker__table) td {
    display: block;
    padding: 10px 5px;
  }
  list-loader table:not(.picker__table) td > * {
    display: inline-block;
  }
  list-loader table:not(.picker__table) td:last-child:before {
    display: none;
    width: 0;
  }
  list-loader table .row-with-info > td:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  list-loader table tr:last-child > td:last-child {
    border-bottom: none;
  }
}
.no-results {
  font-style: italic;
  font-size: 1.2em;
}
i.action {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
i.action.action-remove {
  color: #b71c1c !important;
}
.missing-value {
  font-style: italic;
  color: #9e9e9e;
}
form {
  max-width: 600px;
}
form .checkbox-container {
  padding: 1em 0;
}
form .inline-input > div.input-field,
form .inline-input .checkbox-container {
  display: inline-block;
}
form .form-section-title {
  font-size: 1.2em;
  margin: 1em 0;
  color: #7D943C;
}
form .collapsible-body {
  padding: 1em;
}
form .collapsible-header-with-actions {
  line-height: 1rem;
  padding: 1rem;
}
form .attribute-meta-actions {
  float: right;
  margin-top: -1rem;
}
form .info {
  margin: 0 24px;
}
form .add-attribute {
  margin: 1em 24px;
}
form .copy-dimensions {
  display: block;
  margin: 1em 24px;
}
form li.error .collapsible-header,
form li.error .collapsible-header .missing-value {
  color: #f44336 !important;
}
form .input-field label {
  white-space: nowrap;
}
.validation-error {
  color: #f44336 !important;
  margin-top: 1em;
}
.form-autocomplete-select {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.form-autocomplete-select .select-title {
  font-size: 1.2em;
}
.form-autocomplete-select .input-field {
  display: flex;
}
.form-autocomplete-select .autocomplete-content {
  margin-top: 3.5rem;
  left: 0;
}
.form-autocomplete-select label.active > i {
  display: none;
}
.form-autocomplete-select i.left {
  margin-right: 5px;
  margin-top: -3px;
}
.form-autocomplete-select i.clear {
  color: #9e9e9e;
  line-height: 3rem;
  cursor: pointer;
}
.form-autocomplete-select .form-select-search-action > span {
  color: #757575;
  font-style: italic;
}
.form-autocomplete-select .loading-results {
  padding: 0.5em;
  overflow-y: hidden;
}
.form-autocomplete-select .more-results :hover {
  background-color: white;
  cursor: default;
}
.form-autocomplete-select .more-results > span {
  color: #9e9e9e;
  font-style: italic;
}
.form-autocomplete-select .selected-entities {
  margin-top: -10px;
  margin-bottom: 1em;
}
.form-autocomplete-select .selected-entities > li > div {
  display: inline-block;
}
.form-autocomplete-select .selected-entities span.action {
  display: block;
  min-height: 36px;
  margin-top: 5px;
}
.form-autocomplete-select .selected-entities span.action > div {
  margin-left: 32px;
}
.form-input-line {
  display: flex;
  align-items: center;
}
.form-input-line > * {
  margin-right: 2em;
}
.form-input-line > *:last-child {
  margin-right: 0;
}
.form-input-line > * {
  margin-right: 2em;
}
.form-input-line > *:last-child {
  margin-right: 0;
}
.form-input-line > i.action {
  margin-right: 1em;
}
.form-input-line > .no-shrink {
  flex-shrink: 0;
}
.form-input-line > form-select-search > .form-autocomplete-select {
  margin-bottom: 0;
}
.form-input-line > * {
  flex: 1;
}
.form-input-line > *.dont-expand {
  flex: initial;
}
.form-input-line-expand {
  display: flex;
  align-items: center;
}
.form-input-line-expand > * {
  margin-right: 2em;
}
.form-input-line-expand > *:last-child {
  margin-right: 0;
}
.form-input-line-expand > * {
  margin-right: 2em;
}
.form-input-line-expand > *:last-child {
  margin-right: 0;
}
.form-input-line-expand > i.action {
  margin-right: 1em;
}
.form-input-line-expand > .no-shrink {
  flex-shrink: 0;
}
.form-input-line-expand > form-select-search > .form-autocomplete-select {
  margin-bottom: 0;
}
.form-input-line-expand > * {
  flex: 1;
}
.form-input-line-expand > *.dont-expand {
  flex: initial;
}
.form-input-line-expand > * {
  margin-right: 2em;
}
.form-input-line-expand > *:last-child {
  margin-right: 0;
}
.form-input-line-expand > * {
  margin-right: 2em;
}
.form-input-line-expand > *:last-child {
  margin-right: 0;
}
.form-input-line-expand > i.action {
  margin-right: 1em;
}
.form-input-line-expand > .no-shrink {
  flex-shrink: 0;
}
.form-input-line-expand input {
  min-width: 22ch;
}
.form-input-line-expand.form-input-line-smaller input {
  min-width: 14ch;
}
.form-buttons {
  letter-spacing: 1em;
}
.form-buttons .validation-error {
  letter-spacing: normal;
  margin: 0 0 1em;
}
@media (max-width: 600px) {
  form .form-input-line.form-input-line-breakable {
    display: block;
  }
  form .form-input-line.form-input-line-breakable > * {
    margin-right: 0;
  }
}
.compact-form {
  display: flex;
  max-width: inherit;
  flex-wrap: wrap;
}
.compact-form > * {
  margin-right: 2em;
}
.compact-form .order-by {
  margin-right: auto;
}
.compact-form .form-autocomplete-select {
  margin-top: 0;
}
.compact-form .form-autocomplete-select .input-field,
.compact-form form-text-input .input-field,
.compact-form form-date-input .input-field {
  display: inline-flex;
}
.compact-form .form-autocomplete-select .input-field label,
.compact-form form-text-input .input-field label,
.compact-form form-date-input .input-field label {
  top: 0;
}
.compact-form .form-autocomplete-select .input-field label.active,
.compact-form form-text-input .input-field label.active,
.compact-form form-date-input .input-field label.active {
  top: 0.8rem;
}
.compact-form .form-autocomplete-select .input-field i.clear,
.compact-form form-text-input .input-field i.clear,
.compact-form form-date-input .input-field i.clear {
  line-height: 2rem;
}
.compact-form .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
.compact-form input,
.compact-form .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
.compact-form label > i {
  margin-right: 5px;
}
.compact-form input:not([type]) + label:after,
.compact-form input[type='text'] + label:after {
  top: 46px;
}
form-date-time-input {
  display: flex;
}
form-date-time-input > * {
  margin-right: 1em;
}
form-date-time-input > *:last-child {
  margin-right: 0;
}
form-date-time-input > * {
  margin-right: 1em;
}
form-date-time-input > *:last-child {
  margin-right: 0;
}
input[type='text']:focus:not([readonly]).no-matching-results,
input.no-matching-results {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
body input:not([type]):focus:not([readonly]),
.input-field input:not([type]):focus:not([readonly]),
.input-field input[type='text']:focus:not([readonly]),
.input-field input[type='password']:focus:not([readonly]),
.input-field textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #502709;
  box-shadow: 0 1px 0 0 #502709;
}
.input-field input:not([type]):focus:not([readonly]) + label,
.input-field input[type='text']:focus:not([readonly]) + label,
.input-field input[type='password']:focus:not([readonly]) + label,
.input-field textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #502709;
}
body .dropdown-content li > a,
body .dropdown-content li > span {
  color: #502709;
}
.input-field i.prefix.active {
  color: #502709;
}
.input-field input:not([type]).valid,
.input-field input[type='text'].valid,
.input-field input[type='password'].valid,
.input-field textarea.materialize-textarea.valid {
  border-bottom: 1px solid #9e9e9e;
  box-shadow: none;
}
.input-field input:not([type]):focus.valid,
.input-field input[type='text']:focus.valid,
.input-field input[type='password']:focus.valid,
.input-field textarea.materialize-textarea:focus.valid {
  border-bottom: 1px solid #502709;
  box-shadow: none;
}
body [type='radio']:checked + label:after,
[type='radio'].with-gap:checked + label:after {
  background-color: #502709;
}
body [type='radio']:checked + label:after,
[type='radio'].with-gap:checked + label:before,
[type='radio'].with-gap:checked + label:after {
  border: 2px solid #502709;
}
body [type='radio']:not(:checked) + label:before,
[type='radio']:not(:checked) + label:after {
  border: 2px solid #502709;
}
form-date-input .picker__day.picker__day--today,
form-date-time-input .picker__day.picker__day--today {
  color: #502709;
}
form-date-input .picker__day--selected,
form-date-time-input .picker__day--selected,
form-date-input .picker__day--selected:hover,
form-date-time-input .picker__day--selected:hover,
form-date-input .picker--focused .picker__day--selected,
form-date-time-input .picker--focused .picker__day--selected {
  background-color: #502709;
}
form-date-input .picker__date-display,
form-date-time-input .picker__date-display,
form-date-input .picker__weekday-display,
form-date-time-input .picker__weekday-display {
  background-color: #7D943C;
}
form-date-input .picker__today,
form-date-time-input .picker__today,
form-date-input .picker__close,
form-date-time-input .picker__close {
  background-color: #eee;
  color: #7D943C;
}
.container .collection a.collection-item {
  color: #7D943C;
}
span.no-matching-results {
  color: #f44336 !important;
  font-style: italic;
}
.tour-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.tour-highlight {
  position: relative;
  z-index: 100000;
  pointer-events: none;
}
#horizontal-nav-trials.tour-highlight,
#nav-menu.tour-highlight,
#horizontal-nav-invite.tour-highlight {
  background-color: #7D943C;
}
#first-trial-row-name.tour-highlight,
#first-trial-row-collected.tour-highlight {
  background-color: white;
}
.chart-group.tour-highlight {
  pointer-events: all;
}
#define-first-trial.tour-highlight {
  background-color: white;
}
#add-trial.tour-highlight {
  position: fixed;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  z-index: 100000;
  background-color: white;
}
.server-error-popup h1 {
  color: #f44336 !important;
}
loading-indicator {
  display: flex;
  align-items: center;
}
loading-indicator .spinner-layer {
  border-color: #ddd;
}
loading-indicator > span {
  margin-left: 1em;
  font-style: italic;
  font-size: 1.2em;
  color: #9e9e9e;
}
loading-indicator.fill-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  justify-content: center;
  z-index: 1;
}
.load-more-results {
  display: block;
  margin-top: 2em;
}
flat-button {
  outline: none;
}
list-loader a {
  color: #7D943C;
}
list-loader .anchor-with-link {
  display: inline-block;
}
list-loader flat-button > span.action {
  margin-bottom: 5px;
  color: #757575;
}
body list-loader .dropdown-content li > a {
  color: #757575;
}
list-loader .list-loader-content > table > thead,
list-loader .list-loader-content > table > tbody > tr {
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
}
.container > .uncontainer {
  display: block;
  margin-left: -5.55555556%;
  margin-right: -5.55555556%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer > *,
.container > list-loader.uncontainer td:first-child,
.container > list-loader.uncontainer td:last-child,
.container > list-loader.uncontainer th:first-child,
.container > list-loader.uncontainer th:last-child {
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer .picker__table td:last-child,
.container > list-loader.uncontainer .picker__table td:first-child,
.container > list-loader.uncontainer .picker__table th:first-child,
.container > list-loader.uncontainer .picker__table th:last-child {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer > .list-loader-content {
  padding-left: 0;
  padding-right: 0;
}
list-loader.uncontainer {
  margin-bottom: 6em;
}
@media only screen and (min-width: 601px) {
  .container > .uncontainer {
    display: block;
    margin-left: -8.82352941%;
    margin-right: -8.82352941%;
  }
}
@media only screen and (min-width: 993px) {
  .container > .uncontainer {
    display: block;
    margin-left: -21.42857143%;
    margin-right: -21.42857143%;
  }
}
flat-button > span {
  margin-right: 1em;
}
flat-button.remove > span.action {
  color: #b71c1c !important;
}
flat-button.no-margin > span.action > div {
  margin-left: 0;
  margin-right: 0;
}
.browser-not-supported {
  background: url(/static/17e38cb39ce94d5e7880382f335097df.png) no-repeat 50% 0%;
  background-size: contain;
  max-width: 500px;
  margin: 1em auto;
  padding: 120px 2em;
  font-size: 1.2em;
  text-align: center;
}
.info {
  color: #9e9e9e;
  font-style: italic;
}
.info > i {
  margin-right: 0;
}
.info > div {
  margin-left: 2.5em;
  margin-bottom: 2em;
}
span.action {
  display: inline-block;
  line-height: 36px;
  cursor: pointer;
  color: #502709;
}
span.action > i {
  margin-right: 5px;
  line-height: inherit;
}
span.action > div {
  margin-left: 32px;
  line-height: inherit;
}
span.action.remove {
  color: inherit;
}
span.action.remove > i {
  color: #b71c1c !important;
}
table th > i.material-icons {
  display: none;
  font-size: 20px;
  margin-right: 5px;
}
table th {
  color: #757575;
}
table th.is-sorted {
  color: inherit;
}
table th.is-sorted > i.material-icons {
  display: inline-block;
}
table actions-menu flat-button > .action {
  width: auto;
  margin: 0;
}
table td.actions-cell {
  position: relative;
  line-height: 3em;
  padding: 5px;
}
table td.actions-cell actions-menu .dropdown-content {
  top: 5px;
  right: 5px;
  min-width: 150px;
}
table .row-with-info > td {
  padding-bottom: 0;
}
table .row-info > td {
  padding: 10px 0 1em 0;
  font-size: 12px;
  color: #9e9e9e;
}
table .row-info > td > span {
  display: inline-block;
  margin-right: 2em;
  margin-bottom: 5px;
}
table .row-info > td > span i.material-icons {
  font-size: 20px;
  margin-right: 5px;
}
list-loader tr.row-with-info {
  border-bottom: none;
}
list-loader tr.row-info {
  border-top: none;
}
.customize {
  display: inline-block;
  margin-bottom: 1em;
}
.loosen-text {
  margin-top: 2em;
  margin-bottom: 2em;
}
.actions-menu-container {
  position: relative;
}
actions-menu {
  float: right;
}
actions-menu .dropdown-content {
  top: 0;
  right: 0;
  opacity: 1;
  display: block;
}
actions-menu a.remove {
  color: #b71c1c !important;
}
body .progress {
  background-color: rgba(80, 39, 9, 0.2);
}
body .progress .determinate {
  background-color: #502709;
}
forms-stack qt-popup {
  z-index: 99998;
}
forms-stack qt-popup .popup-content {
  text-align: left;
}
forms-stack nav {
  box-shadow: none;
  background-color: transparent;
}
forms-stack nav .breadcrumb {
  color: #9e9e9e;
}
forms-stack nav .breadcrumb:last-child {
  color: #7D943C;
}
forms-stack nav .breadcrumb:before {
  color: #9e9e9e;
}
forms-stack h1 {
  display: none;
}
forms-stack form.card {
  margin: 0;
  box-shadow: none;
}
forms-stack form.card .card-content {
  padding: 0 0 20px 0;
}
forms-stack form.card .card-action {
  padding: 20px 0;
}
forms-stack .picker--opened .picker__holder {
  background: none;
}
body .card-tabs {
  background-color: #eee;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.14), 2px -2px 2px rgba(0, 0, 0, 0.06);
  display: flex;
}
.tabs-decorator {
  display: flex;
  align-items: center;
  border-left: 1px solid #d0d0d0;
  padding-left: 0.5em;
}
.tabs-decorator .new {
  color: #757575;
  margin-right: 0.5em;
  cursor: pointer;
}
body .tabs {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: auto;
  border-bottom: 1px solid #eee;
  background-color: inherit;
}
body .tabs .tab {
  height: initial;
  text-align: left;
}
body .tabs .tab.error > a {
  color: #f44336;
}
body .tabs .tab.active {
  border-bottom: 2px solid #7D943C;
}
body .tabs .tab.active > a {
  color: #7D943C;
}
body .tabs .tab .input-field {
  margin-top: 0;
}
body .tabs .tab .input-field label {
  position: relative;
}
body .tabs .tab .input-field label.active {
  font-size: inherit;
}
body .tabs .tab .input-field label.active:after {
  transform: translateY(-120%);
}
body .tabs .tab .input-field input[type='text'] {
  height: 2em;
  background-color: white;
  padding: 0 0.5em;
  width: auto;
  margin: 0;
  border-bottom: none;
  box-shadow: none;
}
body .tabs .tab .input-field input[type='text'].invalid + label:after {
  text-transform: initial;
  top: initial;
  white-space: normal;
  line-height: initial;
  text-align: left;
  position: relative;
  margin-top: 0.5em;
}
body .tabs .tab a {
  color: #9e9e9e;
  display: flex;
  font-weight: bold;
}
body .tabs .tab a:hover {
  color: #9e9e9e;
  cursor: pointer;
}
body .tabs .tab a i {
  line-height: inherit;
}
@media only screen and (max-width: 992px) {
  body .tabs .tab {
    flex: initial;
  }
}
.dialog-title {
  color: #7D943C;
  font-weight: 400;
  font-size: 2em;
}
.dialog-title-select-dataset {
  margin-bottom: 1em;
}
form-select.allow-empty .dropdown-content li.selected:first-child {
  background-color: inherit;
}
form-select.allow-empty .dropdown-content li:first-child > span {
  color: #9e9e9e;
}
.allow-empty.empty input {
  color: #9e9e9e;
}
.break-margin-collapse {
  overflow: hidden;
  height: 0;
  width: 0;
}
.form-lines li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.form-lines li > * {
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.form-lines li > :first-child {
  width: 250px;
}
.form-lines li flat-button {
  align-self: center;
}
.form-lines li input {
  min-width: 0;
}
.uncontainer.list-loader-actions {
  float: right;
  display: flex;
}
.uncontainer.list-loader-actions loading-indicator {
  margin-right: 1em;
  font-size: 0.9em;
}
@media (min-width: 801px) {
  table .numeric {
    text-align: right;
  }
}
list-loader th,
.sticky-table-header {
  top: -1px;
  position: sticky;
  background-color: white;
  z-index: 1;
}
list-loader th::after,
.sticky-table-header::after {
  content: '';
  display: block;
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
}
.text-area-autoresize-container {
  position: relative;
}
.text-area-autoresize-container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1;
}
.th-italic {
  font-style: italic;
}
.th-bold {
  color: black;
}
.login-logo {
  background: url(/static/135fac802fcb12145fd7b249bf615204.png) no-repeat 50% 0%;
  height: 87px;
  max-width: 350px;
  width: 100%;
  background-size: 100%;
}
p.motto {
  margin: 2rem 0 2rem;
  font-size: 28px;
  font-weight: 300;
  max-width: 400px;
}
.login {
  max-width: 650px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 140px;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .secondary-links {
  border-top: 1px solid #414141;
  margin-top: 4rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.login .secondary-links > a {
  color: #7D943C;
  font-size: 20px;
}
.login .select-team-title {
  font-size: 1.2em;
  margin-bottom: 1em;
  margin-top: 1em;
}
.login a.collection-item {
  text-align: left;
  cursor: pointer;
}
.login #sign-in {
  display: inline-block;
}
.login .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: url(/static/9627be59fdf151c5edb7987df3d35338.png) repeat-x 0% 100%;
  z-index: 2;
}
#ui-sign-in-email-input,
#ui-sign-in-password-input {
  margin-bottom: 0 !important;
}
#ui-sign-in-email-input:focus,
#ui-sign-in-password-input:focus {
  box-shadow: none;
}
.firebaseui-id-submit {
  background-color: #7D943C !important;
}
.firebaseui-id-submit:hover {
  background-color: #7D943C;
  box-shadow: 5px 10px 9px -2px #cfcfcf;
}
.firebaseui-id-secondary-link {
  color: #7D943C !important;
}
.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
  background-color: #7D943C !important;
}
@media (max-height: 600px) {
  body .login p {
    margin: 3rem 0;
    font-size: 24px;
  }
  body .login .secondary-links {
    margin-top: 3rem;
  }
}
@media (max-height: 510px) {
  body .login {
    padding-bottom: 1em;
  }
  body .login .footer {
    display: none;
  }
}
.email-password-sign-in {
  font-size: 1rem;
  text-align: left;
  margin-top: 2em;
}
.email-password-sign-in > :first-child {
  margin-bottom: 1.5em;
  font-weight: 300;
}
.email-password-sign-in button.primary {
  margin-top: 1em;
  padding: 0 1rem;
  line-height: 2rem;
  height: 2rem;
}
.email-password-sign-in .validation-error {
  max-width: 200px;
}
.email-password-sign-in .signin-in-email-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.resend-email {
  font-family: Roboto;
  font-weight: 700;
  color: #7D943C;
  background: none !important;
  border: none;
  padding: 0 !important;
}
.welcome {
  font-weight: 500;
  color: #7D943C;
}
.firebaseui-idp-button[data-provider-id='microsoft.com'] {
  background: #ffff !important;
}
.firebaseui-idp-button[data-provider-id='microsoft.com'] .firebaseui-idp-text {
  color: #757575 !important;
}
.filter-title {
  color: #7D943C;
}
.filter-summary {
  margin-bottom: 1em;
}
.filter-summary .filter-value {
  font-size: 0.8em;
}
.filter-summary .filter-value.not-set {
  color: #9e9e9e;
}
.filter-widget {
  display: flex;
  flex-direction: column;
}
list-loader.list-loader-left-filters > div {
  display: flex;
}
list-loader.list-loader-left-filters form.compact {
  flex-direction: column;
}
list-loader.list-loader-left-filters form.compact .filters-title {
  display: block;
  color: #7D943C;
  font-size: 1.2em;
  margin-bottom: 1em;
}
list-loader .filters-title {
  display: none;
}
list-loader form.compact {
  flex-direction: row;
  margin-bottom: 1rem;
}
list-loader form.compact input {
  width: 150px;
  min-width: inherit;
}
list-loader form.compact .input-field.input-field-select {
  margin-top: 1rem;
}
list-loader form.compact .input-field.input-field-select .select-wrapper input {
  margin-bottom: 0;
}
list-loader form.compact.no-sorting.new-filters {
  justify-content: flex-end;
}
list-loader form.compact.new-filters {
  align-items: flex-start;
  flex-wrap: nowrap;
}
list-loader form.compact.new-filters > list-filters {
  margin-right: 0;
}
list-loader .list-loader-content {
  flex: 1;
}
list-loader .list-loader-content .wide-column {
  min-width: 10rem;
}
list-loader .list-loader-content thead > tr > th {
  text-align: center;
}
list-loader .list-loader-content table.left-aligned-content thead > tr > th {
  text-align: left;
}
list-loader .list-loader-content table.left-aligned-content tbody tr td {
  text-align: left;
}
list-loader .list-loader-content thead > tr > th:first-child,
list-loader .list-loader-content td:first-child {
  text-align: left;
}
list-loader .list-loader-content table tbody td {
  text-align: center;
  padding: 8px 5px;
  vertical-align: top;
}
list-loader .list-loader-content table tbody td:first-child {
  padding-left: 5px !important;
}
list-loader .list-loader-content table thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: white;
  padding: 8px 5px !important;
  vertical-align: top !important;
}
list-loader .list-loader-content table thead th.center,
list-loader .list-loader-content table tbody th.center,
list-loader .list-loader-content table thead td.center,
list-loader .list-loader-content table tbody td.center {
  vertical-align: middle !important;
}
list-loader .list-loader-content table thead th.center input[type='checkbox'],
list-loader .list-loader-content table tbody th.center input[type='checkbox'],
list-loader .list-loader-content table thead td.center input[type='checkbox'],
list-loader .list-loader-content table tbody td.center input[type='checkbox'] {
  left: 0;
  opacity: 1;
  position: inherit;
  width: 1.25rem;
  height: 1.25rem;
  accent-color: #9ed670;
}
list-loader .list-loader-content table thead th:after,
list-loader .list-loader-content th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}
list-loader .list-loader-content table thead th:after {
  bottom: 0;
  border-bottom: 1px solid #d0d0d0;
}
list-loader .list-loader-content table tr.selected-row {
  background-color: #edf5e3;
}
list-loader .list-loader-content table .text-left {
  text-align: left;
}
@media (max-width: 600px) {
  list-loader form.compact.new-filters {
    flex-wrap: wrap;
  }
  list-loader form.compact.new-filters > list-filters {
    margin-left: 0;
  }
}
@media (max-width: 800px) {
  list-loader table tbody td:first-child a {
    white-space: nowrap;
    font-size: 20px;
    width: 23rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 11px !important;
  }
  list-loader table tbody td {
    padding: 4px 16px !important;
    text-align: left !important;
  }
}
@media (max-width: 800px) {
  list-loader.list-loader-left-filters > div {
    flex-direction: column;
  }
  .list-loader-content table tbody tr {
    position: relative;
  }
  .list-loader-content table td {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
  }
  .list-loader-content table td > span.small-screen-caption {
    flex-basis: 10em;
    flex-shrink: 0;
  }
  .list-loader-content table td.actions-cell {
    position: absolute;
    top: 5px;
    right: 0px;
  }
}
.uncontainer.list-loader-actions {
  float: right;
  display: flex;
}
.uncontainer.list-loader-actions loading-indicator {
  margin-right: 1em;
  font-size: 0.9em;
}
.cell-subtext {
  color: #9e9e9e;
  font-size: 0.8em;
}
.task-edit .form-input-line > * {
  flex: 1;
}
.task-producers {
  margin-top: 0;
}
.task-producers li {
  display: flex;
  justify-content: space-between;
}
.task-producers li form-select-search {
  display: block;
  width: 250px;
  margin-right: 1em;
}
.task-producers li flat-button {
  margin-top: 1em;
}
.my-tasks tbody > tr {
  border-left: 4px solid #ffee94;
}
.my-tasks tbody > tr.completed {
  border-left: 4px solid #4caf50;
}
.sticky-filter {
  position: sticky;
  top: 10px;
  height: 700px;
}
.uncontainer.dashboard {
  display: flex;
  margin-bottom: 2em;
}
.uncontainer.dashboard .export-button-container {
  text-align: center;
}
.uncontainer.dashboard.public .actions {
  margin-top: 180px;
}
.uncontainer.dashboard .actions {
  margin-top: 0.5em;
  margin-right: 2em;
  flex-direction: column;
  display: flex;
  max-width: inherit;
  flex-wrap: wrap;
}
.uncontainer.dashboard .actions input {
  min-width: 10rem;
}
.uncontainer.dashboard .actions > * {
  margin-right: 2em;
}
.uncontainer.dashboard .actions .order-by {
  margin-right: auto;
}
.uncontainer.dashboard .actions .form-autocomplete-select {
  margin-top: 0;
}
.uncontainer.dashboard .actions .form-autocomplete-select .input-field,
.uncontainer.dashboard .actions form-text-input .input-field,
.uncontainer.dashboard .actions form-date-input .input-field {
  display: inline-flex;
}
.uncontainer.dashboard .actions .form-autocomplete-select .input-field label,
.uncontainer.dashboard .actions form-text-input .input-field label,
.uncontainer.dashboard .actions form-date-input .input-field label {
  top: 0;
}
.uncontainer.dashboard .actions .form-autocomplete-select .input-field label.active,
.uncontainer.dashboard .actions form-text-input .input-field label.active,
.uncontainer.dashboard .actions form-date-input .input-field label.active {
  top: 0.8rem;
}
.uncontainer.dashboard .actions .form-autocomplete-select .input-field i.clear,
.uncontainer.dashboard .actions form-text-input .input-field i.clear,
.uncontainer.dashboard .actions form-date-input .input-field i.clear {
  line-height: 2rem;
}
.uncontainer.dashboard .actions .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
.uncontainer.dashboard .actions input,
.uncontainer.dashboard .actions .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
.uncontainer.dashboard .actions label > i {
  margin-right: 5px;
}
.uncontainer.dashboard .actions input:not([type]) + label:after,
.uncontainer.dashboard .actions input[type='text'] + label:after {
  top: 46px;
}
.uncontainer.dashboard .actions > div {
  color: #502709;
  font-size: 0.8em;
}
.uncontainer.dashboard .actions > ul {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.uncontainer.dashboard .actions > ul:last-child {
  margin-bottom: 0;
}
.uncontainer.dashboard .kpi {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.uncontainer.dashboard .kpi > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.uncontainer.dashboard .kpi .title {
  margin-right: 1em;
  margin-left: 1em;
  text-align: center;
  color: #9e9e9e;
}
.uncontainer.dashboard .kpi .value-percentage {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto 1em;
  position: relative;
}
.uncontainer.dashboard .kpi .value-percentage .value {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 80px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7D943C;
}
.uncontainer.dashboard .kpi .not-percentage .value > span:first-child {
  margin-right: 1ch;
}
.uncontainer.dashboard .dashboard-item-full-grid {
  grid-column-start: 1;
  grid-column-end: 4;
}
.uncontainer.dashboard .dashboard-item-full-grid-margin {
  margin: 10px;
}
.uncontainer.dashboard .dashboard-content {
  flex: 1;
}
.uncontainer.dashboard .dashboard-content loading-indicator {
  justify-content: center;
}
.uncontainer.dashboard .dashboard-content .dashboard-table-title {
  color: #7D943C;
  text-align: center;
  margin-top: 2em;
}
.uncontainer.dashboard .dashboard-content .dashboard-table-title.is-first {
  margin-top: 0;
}
.uncontainer.dashboard .dashboard-content .info.empty {
  text-align: center;
  margin-top: 0.5em;
}
.uncontainer.dashboard .dashboard-content tbody {
  border-bottom: 1px solid #d0d0d0;
}
.uncontainer.dashboard .dashboard-content .charts > div {
  cursor: pointer;
  position: relative;
  max-width: 800px;
  margin: 0 auto 1em;
}
.uncontainer.dashboard .dashboard-content .charts > div:last-child {
  margin-top: 1em;
}
.uncontainer.dashboard .dashboard-content.no-actions .charts > div {
  max-width: 800px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item {
  border-radius: 4px 0px 4px 4px;
  display: grid;
  grid-template-columns: 1fr 72px 1fr;
  padding: 48px 8px 0 8px;
  padding-bottom: 16px;
  margin-bottom: 96px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-center {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 8px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .sub-info-center {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 8px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-top {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 8px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-top div {
  color: #492C0F;
  margin: auto;
  text-align: center;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .sub-info-top {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 8px;
  max-width: 340px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-list {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .sub-info-list {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  max-width: 240px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-left div {
  color: #492C0F;
  margin-left: auto;
  text-align: right;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-center div {
  color: #492C0F;
  margin: auto;
  text-align: center;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-right div {
  color: #492C0F;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-right.secondary div {
  color: rgba(101, 129, 168, 0.7);
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-vs {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #839345;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-divider-arrow {
  grid-column-start: 1;
  grid-column-end: 4;
  height: 34px;
  background: url(/static/95463391439165ec02933192db04c93f.svg) no-repeat 50% 0%;
  width: 80%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 16px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-divider-short {
  grid-column-start: 1;
  grid-column-end: 4;
  border: 0.5px solid #839345;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 48px;
  width: 144px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-divider-long {
  grid-column-start: 1;
  grid-column-end: 4;
  border: 1px solid #839345;
  width: 80%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 14px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .info-help {
  grid-column-start: 1;
  grid-column-end: 4;
  margin-left: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
  padding-bottom: 8px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .overview-bottom-info {
  padding-top: 104px;
  padding-bottom: 92px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item.diffusion-and-impact .chart {
  grid-column-start: 1;
  grid-column-end: 4;
  height: 152px;
  margin: 4px 32px 32px 32px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .pie-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .pie-chart {
  width: 650px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .chart-title {
  grid-column-start: 1;
  grid-column-end: 4;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.74);
  margin: 0 32px;
  text-align: center;
}
.uncontainer.dashboard .dashboard-content .dashboard-item .bottom-chart {
  grid-column-start: 1;
  grid-column-end: 4;
  padding-left: 144px;
  padding-right: 144px;
}
.uncontainer.dashboard .dashboard-content .dashboard-item.seeds-productions {
  background-color: rgba(131, 147, 69, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-item.registration-and-licensing {
  background-color: rgba(90, 12, 255, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-item.seeds-sales {
  background-color: rgba(101, 129, 168, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-item.diffusion-and-impact {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-item.diffusion-and-impact-suite {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .info-center-aligned {
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
}
.uncontainer.dashboard .dashboard-content .registration-level {
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  margin-bottom: 48px;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables {
  padding-bottom: 16px;
  padding-top: 32px;
  margin-bottom: 96px;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables table {
  margin: auto;
  width: 80%;
  background-color: #FFFFFF;
  padding-bottom: 4px;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables table th {
  padding: 10px 12px;
  border: #B9B9B9 1px solid;
  background-color: rgba(0, 0, 0, 0.06);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables table td {
  padding: 10px 12px;
  border: #B9B9B9 1px solid;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables table .table-total {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables .table-title {
  display: flex;
  justify-content: center;
  padding: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.uncontainer.dashboard .dashboard-content .dashboard-tables.seeds-productions {
  background-color: rgba(131, 147, 69, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-tables.diffusion-and-impact {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-tables.diffusion-and-impact-suite {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-tables.registration-and-licensing {
  background-color: rgba(90, 12, 255, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-tables.seeds-sales {
  background-color: rgba(101, 129, 168, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-tabs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.uncontainer.dashboard .dashboard-content .dashboard-tabs-wrapper h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.74);
  margin-bottom: 1px;
}
.uncontainer.dashboard .dashboard-content .dashboard-tabs-wrapper .dashboard-item-tabs {
  display: flex;
  justify-content: end;
  margin: 0;
}
.uncontainer.dashboard .dashboard-content .dashboard-tabs-wrapper .dashboard-item-tabs li {
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0.5em 1em;
  height: 32px;
  width: 112px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.74);
}
.uncontainer.dashboard .dashboard-content .dashboard-item-tabs.seeds-productions li.active {
  background-color: rgba(131, 147, 69, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-item-tabs.seeds-sales li.active {
  background-color: rgba(101, 129, 168, 0.2);
}
.uncontainer.dashboard .dashboard-content .dashboard-item-tabs.diffusion-and-impact li.active {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-item-tabs.diffusion-and-impact-suite li.active {
  background-color: rgba(73, 44, 15, 0.1);
}
.uncontainer.dashboard .dashboard-content .dashboard-item-tabs.registration-and-licensing li.active {
  background-color: rgba(90, 12, 255, 0.1);
}
.national-circle {
  position: relative;
  margin: auto;
  border-radius: 100%;
  background-color: #492C0F;
  width: 57px;
  height: 57px;
}
.regional-circle {
  position: relative;
  margin: auto;
  border-radius: 100%;
  background-color: #CBCBCB;
  width: 57px;
  height: 57px;
}
.regional-circle::before {
  content: "";
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  bottom: 8px;
  left: 17px;
  background: #492C0F;
  border-radius: 100%;
}
.public-dashboard-header .login-logo {
  margin: 1em auto 0;
}
.public-dashboard-header p.motto {
  margin: 0 auto 1rem;
}
.fullscreen-chart {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.fullscreen-chart > div {
  margin: 2em;
  height: 100%;
}
.fullscreen-chart > div > div {
  background-color: white;
  margin: auto;
  padding: 2em;
  max-height: 80%;
  max-width: 1400px;
  overflow-y: auto;
}
.fullscreen-chart > div > div flat-button {
  display: block;
  float: right;
}
.tasks-enter-go-back {
  margin-top: 1em;
  display: block;
}
.tasks-enter {
  max-width: 800px;
}
.tasks-enter .task-title {
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #7D943C;
}
.tasks-enter .form-input-line > * {
  width: 250px;
}
.single-task-title {
  margin-top: 0.5em;
  margin-bottom: 2em;
  color: #7D943C;
}
@media (max-width: 799px) {
  .form-input-line {
    display: block;
  }
  .form-input-line > * {
    margin-right: 0;
  }
}
.production-edit .info {
  margin-bottom: 2em;
}
.validated-section {
  color: #9e9e9e;
  margin: 1em 0 2em 0;
}
#sale-edit-form {
  min-width: 825px;
}
#sale-card-content {
  width: 825px;
}
.info-help {
  grid-column-start: 1;
  grid-column-end: 4;
  margin-left: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
  padding-bottom: 8px;
}
.production-quantity-edit > div {
  display: flex;
  align-items: center;
}
.production-quantity-edit > div :first-child {
  flex: 1;
}
.production-quantity-edit > div > .early-gen-btn {
  font-size: 0.8rem;
  margin-left: 10px;
  width: 100px;
}
.production-quantity-edit > div > .early-gen-btn i {
  font-size: 20px;
}
.production-quantity-edit > div > .early-gen-btn span.action > div {
  margin-left: 26px;
}
.production-quantity-edit > form-text-input {
  display: block;
  margin-left: 2em;
  margin-right: 100px;
}
.early-gen-btn {
  font-size: 0.8rem;
}
.early-gen-btn i {
  font-size: 20px;
}
.early-gen-btn span.action > div {
  margin-left: 26px;
}
.production-sale-remove-container {
  display: flex;
  justify-content: end;
}
.prod-flex-row-container {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
.prod-flex-row-item-container {
  width: 380px;
  background-color: #eeeeee;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 25px;
}
.sale-flex-row-container {
  display: flex;
  gap: 20px;
}
.sale-flex-row-item-container {
  width: 400px;
  padding: 15px;
  background-color: #eeeeee;
  border-radius: 25px;
}
.assumptions tbody > tr {
  border-left: 4px solid #ffee94;
}
.assumptions tbody > tr.completed {
  border-left: 4px solid #4caf50;
}
.assumption-edit {
  max-width: 800px;
}
.assumption-edit .assumption-context {
  color: #9e9e9e;
  display: flex;
  justify-content: center;
}
.assumption-edit .assumption-context > div:not(:last-child) {
  margin-right: 1em;
}
.assumption-edit .header-tabs {
  margin-bottom: 2em;
}
.assumption-edit .form-lines li > * {
  flex: 1;
}
.assumption-edit .form-lines li > *:last-child {
  flex: 0;
}
.assumption-edit-confirm form-checkbox {
  display: block;
  margin-bottom: 1em;
}
.assumption-edit-confirm input[type="text"].invalid + label::after {
  width: 450px;
}
.header-tabs {
  display: flex;
  justify-content: center;
  margin-top: 0;
}
.header-tabs li {
  display: inline-block;
  cursor: pointer;
  color: #7D943C;
  padding: 0.5em 1em;
}
.header-tabs li.active,
.header-tabs li.active.error {
  color: #7D943C;
  border-bottom: 2px solid #7D943C;
}
.header-tabs li.error {
  color: #f44336;
}
.navigation-bar {
  background-color: #7D943C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.navigation-bar #header-left {
  display: flex;
  align-items: center;
  color: white;
}
.navigation-bar #header-logo {
  background: url(/static/08389f5cf0b9ed2fe2c0633ee0eb4274.png) no-repeat 50% 50%;
  height: 60px;
  width: 55px;
  margin-left: 15px;
  margin-right: 15px;
}
.navigation-bar #username-placeholder {
  font-size: 16px;
}
.navigation-bar #tenant-name-placeholder {
  font-size: 14px;
}
.navigation-bar #email-placeholder {
  display: none;
  font-size: 16px;
}
.navigation-bar #user-info:hover #username-placeholder {
  display: none;
}
.navigation-bar #user-info:hover #email-placeholder {
  display: initial;
}
.navigation-bar #qt-header-logo {
  margin-right: 24px;
  margin-left: 24px;
}
@media (min-width: 1201px) {
  .navigation-bar #qt-header-logo {
    background: url(/static/abb440d8684bad37ebd67f99f207defa.png) no-repeat center;
    background-size: 172px 41px;
    width: 172px;
    height: 41px;
  }
}
@media (max-width: 1200px) {
  .navigation-bar #qt-header-logo {
    background: url(/static/e0358e23b85050b5949629a45dbef7ff.png) no-repeat 50% 50%;
    background-size: 46px 46px;
    width: 46px;
    height: 46px;
  }
}
.navigation-bar #co-brand-logo {
  margin-right: 24px;
}
@media (min-width: 1201px) {
  .navigation-bar #co-brand-logo {
    background: url(/static/36334346d60e0767d7f6a916033b6914.png) no-repeat 50% 50%;
    background-size: 168px 35px;
    width: 168px;
    height: 35px;
  }
}
@media (max-width: 1200px) {
  .navigation-bar #co-brand-logo {
    background: url(/static/b033fb51517502576aee7069b2ec1258.png) no-repeat 50% 50%;
    background-size: 42.5px 42.5px;
    width: 42.5px;
    height: 42.5px;
  }
}
@media (min-width: 1200px) {
  .navigation-bar .white-line {
    border: solid 1px white;
    height: 40px;
    margin-right: 13px;
  }
}
.navigation-bar ul {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navigation-bar ul li {
  transition: background-color 0.3s;
  display: none;
}
.navigation-bar ul li.important,
.navigation-bar ul li.expand {
  display: inline-block;
}
.navigation-bar ul li a {
  color: #ffffff !important;
  transition: background-color 0.3s;
  font-size: 1rem;
  padding: 1.2rem 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navigation-bar ul li a i {
  max-width: 1ch;
}
.navigation-bar ul .selectable:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bar ul.vertical-menu {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu {
  background-color: #7D943C;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  z-index: 100000;
  animation: slide-in 0.2s forwards;
  min-width: 250px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li {
  display: block;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.expand {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li a,
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  padding: 0.5rem 15px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  color: white;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-item {
  padding-left: 2rem;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.nav-group-expanded {
  background-color: rgba(0, 0, 0, 0.05);
}
.navigation-bar.vertical-menu-open .navigation-bar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu {
  background-color: #7D943C;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 100000;
  animation-name: slideInLeft;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  min-width: 250px;
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li {
  display: block;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li.expand {
  display: none;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li a,
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-title {
  padding: 0.5rem 15px;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-title {
  color: white;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-item {
  padding-left: 2rem;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li.nav-group-expanded {
  background-color: rgba(0, 0, 0, 0.05);
}
.navigation-bar.vertical-menu-open-rtl .navigation-bar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 1200px) {
  body .navigation-bar ul > li.important {
    display: none;
  }
  body .navigation-bar.vertical-menu-open ul.vertical-menu > li.important {
    display: block;
  }
}
@media print {
  .navigation-bar {
    display: none;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.tenant-expiring,
.need-page-reload,
.network-outage {
  width: 100%;
  padding: 5px;
  background-color: #ffee94;
  text-align: center;
}
.tenant-expiring a,
.need-page-reload a,
.network-outage a {
  cursor: pointer;
  margin-left: 1em;
}
.tenant-expiring a:first-child,
.need-page-reload a:first-child,
.network-outage a:first-child {
  margin-left: 0;
}
.tenant-expiring .prefer-nowrap,
.need-page-reload .prefer-nowrap,
.network-outage .prefer-nowrap {
  display: inline-block;
}
.tenant-expiring .network-outage-inner,
.need-page-reload .network-outage-inner,
.network-outage .network-outage-inner {
  text-align: left;
  padding-left: 33%;
}
.loading-dots:after {
  display: inline-block;
  animation: dotty steps(1, end) 3.33s infinite;
  content: '';
}
@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
.banner {
  top: 8px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  display: flex;
  z-index: 1400;
  position: fixed;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.banner-validation-error {
  background-color: #ffee94;
}
.banner-message {
  padding: 4px 16px;
}
.banner-inner {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
}
.dialog-remove .dialog-title {
  color: #b71c1c;
}
.dialog-remove dt {
  font-weight: bold;
}
.dialog-remove dd {
  margin-bottom: 0.5em;
  margin-left: 1em;
}
.dialog-remove button.primary,
.dialog-remove button.primary.btn:hover,
.dialog-remove button.primary:focus {
  background-color: #b71c1c;
}
.dialog-remove.bulk-remove .dialog-title {
  color: #7D943C;
}
.dialog-remove.bulk-remove p span {
  font-weight: bold;
}
.dialog-remove.bulk-remove .primary-color-text {
  color: #7D943C;
}
.dialog-remove.bulk-remove button.primary {
  background-color: #7D943C;
}
.dialog-remove.bulk-remove button.primary:hover,
.dialog-remove.bulk-remove button.primary:focus {
  background-color: #5e702d;
}
qt-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
qt-popup .popup-content {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0;
  max-height: 80%;
  overflow-y: auto;
  max-width: 600px;
  width: 90%;
}
qt-popup .popup-content > .popup-content-inner {
  padding: 2em;
}
qt-popup .popup-content > .popup-content-inner > h1 {
  font-size: 2rem;
  margin: 0;
}
qt-popup .popup-content .popup-buttons {
  margin-top: 2em;
}
qt-popup .popup-content .popup-buttons secondary-button {
  margin-left: 1em;
}
qt-popup.big .popup-content {
  max-width: initial;
}
@media (max-width: 800px), (max-height: 800px) {
  body qt-popup .popup-content {
    max-height: 90%;
    width: 95%;
    top: 5%;
  }
  body qt-popup .popup-content > .popup-content-inner {
    padding: 1em;
  }
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0.8rem;
}
.list-header h1 {
  margin: 0 !important;
}
.list-header loading-indicator .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.list-header loading-indicator > span {
  display: none;
}
.list-header .list-header-actions {
  display: flex;
  align-items: flex-end;
}
.list-header .list-header-actions div.actions-chip-text {
  background-color: #ff8a25;
  height: 16px;
  padding: 0px 5px;
  border-radius: 10px;
  font-size: x-small;
  text-align: center;
  position: absolute;
  margin-left: 25px;
  color: white;
}
.list-header .list-header-actions > *:hover {
  background-color: #eeeeee;
}
.list-header .list-header-actions > * {
  cursor: pointer;
  color: #7D943C;
  width: fit-content;
  min-width: 4rem;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-header .list-header-actions > *:last-child {
  margin-right: 0;
}
.list-header .list-header-actions > * i.map-icon {
  display: block;
  height: 24px;
  min-width: 19px;
  background: url(/static/6032956d3ef81e57be2f1eca5eedf37e.png) no-repeat 50%;
}
.list-header .list-header-actions > * i.icon-warning {
  color: #ffee94;
}
.list-header .list-header-actions > * i.icon-danger {
  color: #b71c1c;
}
list-filters {
  display: inline-block;
}
list-filters .tooltip {
  display: none;
}
list-filters span.with-tooltip {
  cursor: pointer;
}
list-filters span.with-tooltip:hover .tooltip {
  margin-top: 10px;
  display: block;
  position: absolute;
  background-color: white;
  z-index: 2;
  border: 1px solid #d0d0d0;
  max-width: 400px;
}
list-filters span.with-tooltip:hover .tooltip .info {
  margin: 0.5em;
}
list-filters .filters {
  border-radius: 4px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 4px;
}
list-filters .filters .picker__input.picker__input--active {
  border-color: #9e9e9e;
}
list-filters .filters form-date-input .input-field {
  display: block;
}
list-filters .filters > div {
  position: relative;
  background-color: rgba(125, 148, 60, 0.1);
}
list-filters .filters > div .input-field {
  height: 100%;
  margin: 0;
}
list-filters .filters > div .input-field > input {
  cursor: pointer;
  border: none;
}
list-filters .filters > div .input-field label {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
list-filters .filters > div .input-field label.active {
  top: 100%;
}
list-filters .filters > div .input-field input[type='text'] {
  height: 100%;
  min-height: 40px;
  margin: 0;
  padding-left: 8px;
  padding-top: 12px;
  box-sizing: border-box;
  /* Ensure border doesn't move with padding */
}
list-filters .filters > div > div {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  min-width: 80px;
  padding: 8px;
}
list-filters .filters > div > div:has(form-date-input) {
  padding: 0;
}
list-filters .filters > div > div > i {
  margin-left: 8px;
}
list-filters .filters > div > div > i[data-icon='check'] {
  color: #7D943C;
}
list-filters .filters .selected-count {
  background: #7D943C;
  color: #FFF;
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  text-align: center;
  margin-left: 8px;
}
list-filters .filters .results-popup {
  min-width: 15rem;
  position: absolute;
  background-color: white;
  z-index: 20;
  padding: 0;
  padding-top: 0.5em;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
list-filters .filters .results-popup loading-indicator {
  margin: 0 0.5em 0.5em;
}
list-filters .filters .results-popup loading-indicator > span {
  font-size: 1em;
}
list-filters .filters .results-popup .preloader-wrapper.small {
  height: 24px;
  width: 24px;
}
list-filters .filters .results-popup .search-input {
  display: flex;
  align-items: center;
  padding-right: 24px;
  margin: 0 0.5em;
}
list-filters .filters .results-popup .search-input > input {
  width: 100%;
  padding-right: 24px;
  margin-bottom: 1em;
  height: 2.5rem;
  border-bottom: 1px solid #757575;
  box-shadow: none;
}
list-filters .filters .results-popup .search-input > i {
  height: 2.9rem;
  margin-left: -24px;
}
list-filters .filters .results-popup .search-results {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0.5em;
}
list-filters .filters .results-popup .search-results > div {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}
list-filters .filters .results-popup .search-results > div > i {
  margin-right: 5px;
}
list-filters .filters .results-popup .search-results > div > i[data-icon='check_box'],
list-filters .filters .results-popup .search-results > div > i[data-icon='radio_button_checked'] {
  color: #7D943C;
}
list-filters .filters .results-popup .search-results .more-results {
  color: #9e9e9e;
  font-style: italic;
}
list-filters .filters .results-popup .buttons-container {
  display: flex;
  width: 100%;
  padding: 1px;
}
list-filters .filters .results-popup .buttons-container > div {
  cursor: pointer;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 0.5em;
  margin-top: 0.5em 0;
  font-weight: 700;
  font-family: Roboto;
  font-size: 16px;
}
list-filters .filters .results-popup .reset {
  background: #D8D8D8;
  color: #404040;
  flex: 1;
}
list-filters .filters .results-popup .save {
  background-color: #7D943C;
  color: #FFF;
  flex: 2;
}
list-filters .filters .range-filter {
  padding-bottom: 0.5em;
}
list-filters .filters .range-filter input {
  width: 100%;
}
list-filters .filters .range-filter form-text-input input[type="text"].invalid + label::after {
  display: none;
}
list-filters .filters .range-filter .validation-error {
  color: #f44336;
  font-size: 1rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}
list-filters .reset-all-filters {
  background-color: #FFF !important;
  color: rgba(0, 0, 0, 0.74);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
list-filters .reset-all-filters > div {
  justify-content: center;
}
list-filters .reset-all-filters:hover {
  background-color: #EEEEEE !important;
}
list-filters #edit-filters-button {
  border: 1px solid #e8e8e8;
  border-radius: 5%;
  display: inline-flex;
}
list-filters #edit-filters-button span.action {
  line-height: inherit;
  width: 3.5rem;
  max-width: 40px;
  margin-right: 0;
  text-align: center;
}
list-filters #edit-filters-button i {
  width: 100%;
}
list-filters #edit-filters-button:hover {
  background-color: #eeeeee;
}
.i18n-text-input .language-extra-translations {
  margin-left: 3em;
  margin-bottom: 2em;
}
.i18n-text-input .language-row {
  align-items: baseline;
}
.i18n-text-input .language-row-textarea {
  align-items: center;
}
.i18n-text-input .language-row,
.i18n-text-input .language-row-textarea {
  display: flex;
}
.i18n-text-input .language-row .input-field,
.i18n-text-input .language-row-textarea .input-field,
.i18n-text-input .language-row form-text-input,
.i18n-text-input .language-row-textarea form-text-input,
.i18n-text-input .language-row form-textarea,
.i18n-text-input .language-row-textarea form-textarea {
  flex: 1;
  margin-right: 1em;
}
.i18n-text-input .language-row .language-info,
.i18n-text-input .language-row-textarea .language-info {
  text-transform: uppercase;
  color: #9e9e9e;
}
.i18n-text-input .language-row flat-button .material-icons,
.i18n-text-input .language-row-textarea flat-button .material-icons {
  font-size: 14px;
}
.i18n-text-input .language-row flat-button .action div,
.i18n-text-input .language-row-textarea flat-button .action div {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 0.8em;
}
.i18n-text-input .language-row flat-button > span,
.i18n-text-input .language-row-textarea flat-button > span {
  margin-right: 0;
}
.import-facts.go-back {
  color: #9e9e9e;
  display: block;
  margin-bottom: 1em;
  cursor: pointer;
}
.import-facts .description {
  margin-bottom: 1em;
}
.import-facts .download-template .error {
  color: #f44336;
  margin-left: 32px;
  margin-bottom: 0.5em;
}
.import-facts .success {
  color: #4caf50;
  margin-top: 1em;
}
.import-facts .import-errors {
  margin-left: 32px;
}
.import-facts .import-errors > span,
.import-facts .import-errors td:last-child {
  color: #f44336;
}
.import-facts loading-indicator {
  line-height: 36px;
}
.import-facts loading-indicator > span {
  font-size: 1em;
}
.import-facts .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.import-facts .text-with-icon > i.left,
.import-facts.text-with-icon > i.left {
  margin-right: 10px;
}
.import-facts .text-with-icon > span,
.import-facts.text-with-icon > span {
  line-height: 26px;
}
.import-facts-links {
  display: flex;
  justify-content: space-between;
}
.import-facts-secondary-links {
  display: flex;
}
.import-facts-secondary-links a {
  color: #7D943C;
}
.import-update-facts-options {
  margin-top: 2em;
}
.column-select-container {
  height: 14px;
  display: inline-block;
  vertical-align: text-top;
}
.column-select-container .column-select {
  position: relative;
  padding: 6px;
  background-color: white;
  border: 1px solid #d0d0d0;
  z-index: 2;
  left: -1em;
}
.column-select-container .column-select > flat-button {
  display: block;
  text-align: center;
  cursor: pointer;
}
.changelog .changes {
  white-space: pre-wrap;
}
.project-edit-form {
  max-width: 1200px;
}
.project-edit-item-main {
  flex: 2 1 0;
}
.project-edit-item {
  flex: 1 1 0;
}
.project-edit-item-actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
